import { NetworkUrlOfChainId, EthRPCEndpoints } from 'common/constants';
//const host = 'http://localhost';
const host = 'http://host.dnerochain.xyz';
const hostWs = 'http://ws.dnerochain.xyz';
const config = {
    restApi: {
        host: host,
        port: 7554
    },
    socketApi: {
        host: hostWs,
        port: 2096
    },
    defaultDneroChainID: NetworkUrlOfChainId[host],
    ethRPCEndpoint: EthRPCEndpoints[host]
};
export default config;
